#drop_target {
  background-color: var(--bg2);
  border: dashed 2px var(--text-dim);
  padding: 4em 1em;
  border-radius: var(--rad_sm);
}

#drop_target.drag {
  background-color: #ccf;
  border-color: #68a;
}

#drop_target * {
  pointer-events: none;
}
