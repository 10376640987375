#graph {
  &.centered {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  flex-grow: 1;
  height: 100vh;
  overflow: auto;
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  padding-right: var(--splitter-size);

  --warning0: #f6f6e0;
  --warning1: #d9d9d9;
  --stub: #f00;
}

@media (prefers-color-scheme: dark) {
  #graph {
    --warning0: #101010;
    --warning1: #660;
  }
}

#graph-controls {
  display: flex;
  position: fixed;
  bottom: 1em;
  left: 1em;
  cursor: default;

  button {
    display: inline-block;
    font-size: 1.8em;
    line-height: 1em;
    margin: 0;
    color: var(--text);
    border: solid 1px var(--bg1);
    background: var(--bg0);
    border-radius: var(--rad_sm);
    outline: 0;

    &.selected {
      background-color: var(--highlight);
    }
  }
}

svg {
  fill: #666;
}

pattern#warning {
  .line0 {
    stroke: var(--warning0);
  }
  .line1 {
    stroke: var(--warning1);
  }
}

g .stub {
  opacity: 0.6;

  > path {
    stroke-dasharray: 4, 4;
    stroke: var(--stub);
  }
}

g.node {
  text {
    fill: var(--text);
  }

  path {
    stroke: var(--text);
    fill: var(--bg0);
  }

  &.collapsed,
  &.peer {
    opacity: 0.5;
  }

  &.selected > path {
    stroke-width: 3;
    stroke: var(--highlight);
  }

  &.warning > path {
    fill: url(#warning);
  }

  * {
    cursor: pointer;
  }
}

g.edge {
  > path {
    // stylelint-disable-line no-descending-specificity
    stroke: var(--text);
  }

  > polygon {
    stroke: var(--text);
    fill: var(--text);
  }

  &.selected > path {
    stroke-width: 3;
    stroke: var(--highlight);
  }

  &.selected > polygon {
    stroke: var(--highlight);
    fill: var(--highlight);
  }
}

@media print {
  #graph-controls {
    display: none;
  }
}
