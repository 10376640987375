:root {
  color-scheme: light dark;
  --rad_sm: 5px;
  --rad_lg: 10px;
  --splitter-size: 2em;
  --inspector-size: 50em;
  --tabs-height: 24pt;
  --bg-gradient: linear-gradient( to bottom, rgba(255, 255, 255, .2), transparent 100% );
  --text: #020303;
  --text-dim: #999;
  --bg0: #f1f4f4;
  --bg1: #d6d6d6;
  --bg2: #ddd;
  --tabs-bg: #7e7e7d;
  --tabs-text: #fff;
  --highlight: #e65c00;
}

@media (prefers-color-scheme: dark) {
  :root {
    --text: #f3f3f3;
    --text-dim: #766;
    --bg0: #262222;
    --bg1: #696666;
    --bg2: #333;
    --highlight: #ff761a;
    --inset-shadow-color: black;
    background-color: #111;
  }
}

html {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
  font-size: 10pt;
}

html, body {
  margin: 0;
  padding: 0;
}

body.fail {
  background: url("fail_giraffe.799c319e.png") right 0 no-repeat;
  background-color: var(--bg1);
  background-size: 30%;
}

#app {
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

p {
  line-height: 1.5em;
}

h2 {
  border-radius: var(--rad_sm);
  font-size: 14pt;
}

.material-icons {
  font-size: inherit;
}

.flash {
  border-radius: var(--rad_sm);
  padding: 4px;
  transition-property: left;
  transition-duration: .7s;
  position: fixed;
}

.loader {
  width: calc(var(--inspector-size)  + var(--splitter-size));
  z-index: 99;
  line-height: var(--tabs-height);
  text-align: center;
  color: var(--tabs-text);
  background: var(--tabs-bg) var(--bg-gradient);
  border-top-left-radius: var(--rad_lg);
  border-bottom-left-radius: var(--rad_lg);
  font-weight: 600;
  position: absolute;
  right: 0;
  overflow: hidden;
}

@keyframes MOVE-BG {
  from {
    transform: translateX(-56.57px);
  }

  to {
    transform: translateX(0);
  }
}

.loader .bg {
  z-index: -1;
  width: calc(100% + 57px);
  height: 2pt;
  background: repeating-linear-gradient(-45deg, white 0, white 20px, var(--highlight) 20px, var(--highlight) 40px);
  animation-name: MOVE-BG;
  animation-duration: 1.6s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: absolute;
  bottom: 0;
}

#graph {
  height: 100vh;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-right: var(--splitter-size);
  --warning0: #f6f6e0;
  --warning1: #d9d9d9;
  --stub: red;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
  overflow: auto;
}

#graph.centered {
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
}

@media (prefers-color-scheme: dark) {
  #graph {
    --warning0: #101010;
    --warning1: #660;
  }
}

#graph-controls {
  cursor: default;
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  bottom: 1em;
  left: 1em;
}

#graph-controls button {
  color: var(--text);
  border: solid 1px var(--bg1);
  background: var(--bg0);
  border-radius: var(--rad_sm);
  outline: 0;
  margin: 0;
  font-size: 1.8em;
  line-height: 1em;
  display: inline-block;
}

#graph-controls button.selected {
  background-color: var(--highlight);
}

svg {
  fill: #666;
}

pattern#warning .line0 {
  stroke: var(--warning0);
}

pattern#warning .line1 {
  stroke: var(--warning1);
}

g .stub {
  opacity: .6;
}

g .stub > path {
  stroke-dasharray: 4 4;
  stroke: var(--stub);
}

g.node text {
  fill: var(--text);
}

g.node path {
  stroke: var(--text);
  fill: var(--bg0);
}

g.node.collapsed, g.node.peer {
  opacity: .5;
}

g.node.selected > path {
  stroke-width: 3px;
  stroke: var(--highlight);
}

g.node.warning > path {
  fill: url("#warning");
}

g.node * {
  cursor: pointer;
}

g.edge > path {
  stroke: var(--text);
}

g.edge > polygon {
  stroke: var(--text);
  fill: var(--text);
}

g.edge.selected > path {
  stroke-width: 3px;
  stroke: var(--highlight);
}

g.edge.selected > polygon {
  stroke: var(--highlight);
  fill: var(--highlight);
}

@media print {
  #graph-controls {
    display: none;
  }
}

#pane-graph .collapsed {
  opacity: .5;
}

#drop_target {
  background-color: var(--bg2);
  border: dashed 2px var(--text-dim);
  border-radius: var(--rad_sm);
  padding: 4em 1em;
}

#drop_target.drag {
  background-color: #ccf;
  border-color: #68a;
}

#drop_target * {
  pointer-events: none;
}

.bundle-item {
  box-sizing: border-box;
  cursor: default;
  text-align: center;
  border-radius: var(--rad_sm);
  background-color: var(--bg1);
  -ms-flex-line-pack: center;
  align-content: center;
  display: grid;
  position: absolute;
  overflow: hidden;
}

.bundle-item > span {
  color: rgba(0, 0, 0, .5);
  font-size: 115%;
  font-weight: 600;
}

.score-bar, #treemap {
  color: #000;
}

#pane-graph .collapsed {
  opacity: .5;
}

#inspector {
  height: 100vh;
  color: var(--text);
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  transition: -ms-flex-basis .5s, flex-basis .5s;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
}

#inspector.open {
  flex: 0 0 var(--inspector-size);
}

#inspector .description {
  font-weight: 600;
}

#inspector .module {
  white-space: nowrap;
}

#inspector .tag {
  cursor: pointer;
  background-color: var(--bg1);
  color: var(--text);
  border-radius: var(--rad_lg);
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 2px 8px;
  overflow: hidden;
}

#inspector .tag.maintainer {
  height: 32px;
  padding: 0;
}

#inspector .tag.maintainer img {
  vertical-align: middle;
  margin-right: 4px;
}

#inspector footer {
  color: var(--text-dim);
  text-align: center;
  box-sizing: border-box;
  width: 50em;
  padding: .5em;
}

#inspector footer #github {
  color: inherit;
  text-decoration: none;
}

label {
  margin: .3em 0;
  display: block;
}

summary {
  border-top: solid 3px var(--bg1);
  cursor: pointer;
  outline: none;
  margin: .7em 0 .5em;
  padding: .2em;
  font-size: 1.2em;
  font-weight: 600;
  position: -webkit-sticky;
}

#tabs {
  background: var(--tabs-bg) var(--bg-gradient);
  color: var(--tabs-text);
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  display: -ms-flexbox;
  display: flex;
}

#tabs > * {
  line-height: var(--tabs-height);
}

#tabs .tab {
  cursor: pointer;
  background: var(--tabs-bg) var(--bg-gradient);
  border-left: 1px solid #888;
  padding: 0 10px;
  font-size: 12pt;
  font-weight: 600;
}

#tabs .tab.active {
  background: var(--highlight);
  border-color: rgba(0, 0, 0, 0);
}

#tabs #search-field {
  font: inherit;
  border: 1px solid #fff;
  border-radius: 15px;
  outline: none;
  margin-left: 1em;
  padding: .3em .7em;
  line-height: 1em;
}

#tabs #search-field:focus {
  border-color: var(--highlight);
}

.pane {
  width: 50em;
  color: var(--text);
  background-color: var(--bg0);
  box-sizing: border-box;
  border-bottom-left-radius: var(--rad_lg);
  box-shadow: inset 1px -1px 6px var(--inset-shadow-color, rgba(214, 214, 214, .467));
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding: .5em 1em;
  overflow-x: hidden;
  overflow-y: auto;
}

.pane a {
  color: var(--highlight);
}

.bright-hover {
  transition: filter .5s;
}

.bright-hover:hover {
  filter: brightness(125%);
  transition-duration: .15s;
}

.warning {
  background: repeating-linear-gradient(-45deg, rgba(128, 128, 0, .1), rgba(128, 128, 0, .1) 10px, rgba(0, 0, 0, .1) 10px, rgba(0, 0, 0, .1) 20px);
}

#splitter {
  box-sizing: border-box;
  height: 24pt;
  color: var(--tabs-text);
  background: var(--tabs-bg) var(--bg-gradient);
  border-top-left-radius: var(--rad_lg);
  border-bottom-left-radius: var(--rad_lg);
  flex-basis: var(--splitter-size);
  margin-left: calc(-1 * var(--splitter-size));
  z-index: 1;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 10px;
  display: -ms-flexbox;
  display: flex;
}

@media print {
  #inspector, #splitter {
    display: none;
  }
}

/*# sourceMappingURL=index.4829e3e8.css.map */
