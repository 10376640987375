.flash {
  position: fixed;
  padding: 4px;
  transition-property: left;
  transition-duration: 0.7s;
  border-radius: var(--rad_sm);
}

.loader {
  position: absolute;
  right: 0;
  width: calc(var(--inspector-size) + var(--splitter-size));
  z-index: 99;
  line-height: var(--tabs-height);
  overflow: hidden;
  font-weight: 600;
  text-align: center;
  color: var(--tabs-text);
  background: var(--tabs-bg) var(--bg-gradient);
  border-top-left-radius: var(--rad_lg);
  border-bottom-left-radius: var(--rad_lg);

  @keyframes MOVE-BG {
    from {
      transform: translateX(-56.57px);
    } // sqrt(2) * gradient_width
    to {
      transform: translateX(0);
    }
  }

  .bg {
    position: absolute;
    z-index: -1;
    width: calc(100% + 57px);
    bottom: 0;
    height: 2pt;
    background: repeating-linear-gradient(
      -45deg,
      white 0,
      white 20px,
      var(--highlight) 20px,
      var(--highlight) 40px
    );
    animation-name: MOVE-BG;
    animation-duration: 1.6s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
}
