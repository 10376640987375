#inspector {
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;
  color: var(--text);
  overflow: hidden;
  transition: flex-basis 0.5s;

  &.open {
    flex: 0 0 var(--inspector-size);
  }

  .description {
    font-weight: 600;
  }

  .module {
    white-space: nowrap;
  }

  .tag {
    cursor: pointer;
    background-color: var(--bg1);
    color: var(--text);
    border-radius: var(--rad_lg);
    padding: 2px 8px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &.maintainer {
      padding: 0;
      height: 32px;

      img {
        vertical-align: middle;
        margin-right: 4px;
      }
    }
  }

  footer {
    color: var(--text-dim);
    text-align: center;
    padding: 0.5em;
    box-sizing: border-box;
    width: 50em;

    #github {
      color: inherit;
      text-decoration: none;
    }
  }
}

label {
  display: block;
  margin: 0.3em 0;
}

summary {
  position: -webkit-sticky;
  font-size: 1.2em;
  font-weight: 600;
  margin: 0.7em 0 0.5em;
  border-top: solid 3px var(--bg1);
  padding: 0.2em;
  outline: none;
  cursor: pointer;
}

#tabs {
  flex-basis: auto;
  flex-shrink: 0;
  display: flex;
  background: var(--tabs-bg) var(--bg-gradient);
  color: var(--tabs-text);
  user-select: none;

  > * {
    line-height: var(--tabs-height);
  }

  .tab {
    padding: 0 10px;
    cursor: pointer;
    background: var(--tabs-bg) var(--bg-gradient);
    border-left: solid 1px #888;
    font-size: 12pt;
    font-weight: 600;

    &.active {
      border-color: transparent;
      background: var(--highlight);
    }
  }

  #search-field {
    font: inherit;
    line-height: 1em;
    padding: 0.3em 0.7em;
    margin-left: 1em;
    border: solid 1px white;
    border-radius: 15px;
    outline: none;

    &:focus {
      border-color: var(--highlight);
    }
  }
}

.pane {
  flex-grow: 1;
  width: 50em;
  padding: 0.5em 1em;
  color: var(--text);
  background-color: var(--bg0);
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  border-bottom-left-radius: var(--rad_lg);
  box-shadow: inset 1px -1px 6px var(--inset-shadow-color, #d6d6d677);

  a {
    color: var(--highlight);
  }
}
